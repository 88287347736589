import { useState, useEffect } from "react";

// @ts-ignore Instead of using the DOM lib, we ignore the Typescript errors here.
const isBrowser = !!(
  // Extremely silly but we need "true &&" or prettier moves our @ts-ignore to the wrong location
  (
    true &&
    // @ts-ignore
    typeof window !== "undefined" &&
    // @ts-ignore
    window.document &&
    // @ts-ignore
    window.document.createElement
  )
);

type UseLoadedUrlOptions = {
  onError?(url: string): any;
  retryUrl?: string;
};

/**
 * An isomorphic method, which when used in the browser allows you to pass an image URL and does
 * not return it until it has loaded.
 */
const useLoadedUrl: (
  url: string,
  options?: UseLoadedUrlOptions
) => string | undefined = isBrowser ? useLoadedUrlBrowser : useLoadedUrlServer;

export default useLoadedUrl;

function useLoadedUrlServer(url: string): string {
  return url;
}

function useLoadedUrlBrowser(
  url: string,
  { onError: onErrorCallback, retryUrl = undefined }: UseLoadedUrlOptions = {}
): string {
  const [loadedUrl, setLoadedUrl] = useState<string>();
  const [urlToLoad, setUrlToLoad] = useState(url);

  useEffect(() => {
    if (!urlToLoad) {
      return;
    }
    // @ts-ignore
    const imageLoader = new Image();
    imageLoader.onload = onLoad;
    imageLoader.onerror = onError;
    imageLoader.src = urlToLoad;
    function onLoad() {
      setLoadedUrl(urlToLoad);
    }
    function onError() {
      if (urlToLoad !== retryUrl && retryUrl) {
        setUrlToLoad(retryUrl);
      } else {
        // Maxed out our retries. Let's give up.
        setLoadedUrl(undefined);
        onErrorCallback && onErrorCallback(urlToLoad);
      }
    }
    return () => {
      imageLoader.onload = null;
      imageLoader.onerror = null;
    };
  }, [urlToLoad, setLoadedUrl, onErrorCallback, retryUrl]); // Added missing dependencies

  useEffect(() => {
    setUrlToLoad(url);
  }, [url]);

  return loadedUrl;
}
